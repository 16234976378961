import logo from './chickenpfp1.png';
import './App.css';
import { Button, Modal } from 'react-bootstrap';
import {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
// import ReactGA from 'react-ga';

// ReactGA.initialize('G-K41T8VSD18');

function App() {
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const onclickredir = function() {
    var should = true
    if (should === true) {
      window.open('https://commerce.coinbase.com/checkout/68dbe3f8-21a1-4b70-a778-86654dc567cf', '_about')
    } else {
      return
    }
  }
  return (
    <div className="App">
    <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2260715583770557"
     crossorigin="anonymous"></script>
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hi, i'm <code>editid</code>
        </p>
        <Button variant="primary" onClick={handleShow1}>Social Links</Button>
        <Button variant="success" onClick={handleShow2} className="button">Links</Button>
        <Button variant="warning" onClick={onclickredir}>Donate (please)</Button>
        <Modal show={show1} onHide={handleClose1}>
        <Modal.Header closeButton>
          <Modal.Title>Social links</Modal.Title>
        </Modal.Header>
        <Modal.Body>Discord: <a href="https://discord.com/users/598614955583078400">editid#6714</a><br />Github: <a href="https://github.com/editid0">editid0</a><br />PyPi: <a href="https://pypi.org/user/editid/">editid</a><br /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose1}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
        <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>My social links</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <a href="https://billing.galaxygate.net/aff.php?aff=105" target="_blank" rel="noreferrer">
              Galaxygate VPS Hosting
            </a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Close
          </Button>
        </Modal.Footer>
        </Modal>
      </header>
    </div>
  );
}

export default App;
